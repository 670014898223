import Swiper, {Pagination, Navigation, Autoplay} from "swiper";

export const initBlogImagesSlider = () => {
  const blogImagesSlider = document.querySelector(".blog-images-swiper");

  if(!blogImagesSlider) {
    return;
  }

  const blogImagesSwider = new Swiper(blogImagesSlider, {
    modules: [Pagination, Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 20,
    slideActiveClass: 'blog-images-item__active',
    loop: true,
    navigation: {
      nextEl: ".blog-images-next",
      prevEl: ".blog-images-prev",
    },
    pagination: {
      el: '.blog-images-pagination',
      clickable: true,
      bulletActiveClass: 'blog-images-pagination__bullet-active',
      bulletClass: 'blog-images-pagination__bullet'
    }
  })
}