import "./scss/app.scss";

import priceRange from "./modules/noUISlider";
import NiceSelect from "././library/nice-select2/src/js/nice-select2";

import { initWebinarsSlider } from "./modules/pages_1c";
import { initBlogImagesSlider } from "./modules/blogImages";

import menu, { toggleMenuScrollState } from "./modules/menu";

import openVideo from "./modules/video";

import { animationParallaxVideoHomePage } from "./modules/parallax";

import serviceSliders from "./modules/sliders";
import vacancyInfo from "./modules/vacancyInfo";
import goBack from "./modules/goBack";

import tabs from "./modules/tabs";
import infographics from "./modules/infographics";

import initFullscreenImage from "./modules/fullscreenImage";
import pagination from "./modules/pagination";
import projectReport from "./modules/projectReport";

import {
  modalFeedbackForm,
  careerForm,
  projectStartForm,
  subscribeForm,
  academyForm,
  informationSafetyForm,
  informationSafetyCommentForm,
  onecCoursePlanForm,
  rndForm,
  oneCServicesForm,
  eventRegisterForm,
  onecCheckListForm
} from "./modules/form";

import animation from "./modules/animation";
import cases from "./modules/cases";
import { eventsPlayVideos } from "./modules/events";
import animationCounter from "./modules/animationCounter";
import { customStartCount, customVh } from "./modules/customVariables";
import { showMore } from "./modules/showMore";
import serviceWorkerInit from "./modules/serviceWorkerInit";
import initCookieNotification from "./modules/cookieNotification";
import { employeePage } from "./modules/employee";
import { initModalButtons } from "./modules/modal";
import accordion from "./modules/accordion";
import bitrix24Widget from "./modules/b24";
// import initCaptcha from "./modules/captcha";
import setScrollbarWidth from "./modules/initScrollWidth";
import { tableMnemonic } from "./modules/table";
import garpixTeamPage from "./modules/garpixTeam";
import gitlabPtojectsCopyReports from "./modules/gitlabPtojects";

window.addEventListener("DOMContentLoaded", () => {
  /*
	OPEN MENU
	CLOSE MENU
	ANIMATION GAMBURGER
	*/
  menu();
  cases();
  /* HEADER */
  if (document.querySelector(".js-header")) {
    toggleMenuScrollState(".js-header");
  }

  /*OPEN VIDEO*/
  if (document.querySelector(".video__button")) {
    openVideo(".video__button", ".video__wrap");
  }

  /*PARALLAX VIDEO IN HOMEPAGE*/
  if (document.querySelector(".rellax1")) {
    animationParallaxVideoHomePage();
  }

  if (document.querySelector(".js-custom-select")) {
    new NiceSelect(document.querySelector(".js-custom-select"), {
      placeholder: "",
    });
  }

  const formSelects = Array.from(document.querySelectorAll(".js-form-select"));
  if (formSelects.length > 0) {
    formSelects.forEach(select => {
      select.addEventListener('change', function() {
        this.form?.submit();
      })
    });
  }

  const fullscreenImages = Array.from(document.querySelectorAll(
    ".js-open-fullscreen-image"
  ));
  initFullscreenImage(fullscreenImages);

  setScrollbarWidth();

  serviceSliders();

  vacancyInfo();

  // initCaptcha();

  tabs();
  goBack();
  showMore();
  infographics();
  pagination();
  priceRange();
  modalFeedbackForm();
  projectStartForm();
  careerForm();
  subscribeForm();
  academyForm();
  informationSafetyForm();
  informationSafetyCommentForm();
  eventRegisterForm();
  rndForm();
  oneCServicesForm();
  onecCoursePlanForm();
  onecCheckListForm();

  animationCounter();

  initModalButtons();
  accordion();
  eventsPlayVideos();

  // Custom variables
  customVh();
  customStartCount();

  serviceWorkerInit();

  /* Cookie notification */
  initCookieNotification();

  /* Bitrix24 widget */
  bitrix24Widget();

  const runApp = (nodeEl, app) => {
    if(nodeEl) { app() }
  }

  const nodeGitlabProjects = document.querySelector('#gitlab_projects')
  runApp(nodeGitlabProjects, projectReport);

  employeePage();
  garpixTeamPage();
  gitlabPtojectsCopyReports();

  initWebinarsSlider();
  initBlogImagesSlider();

});

animation();

tableMnemonic()
